import React from "react";
import FarmersDashboard from "./FarmersDashboard";
import HiversDashboard from "./HiversDashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BusinessDashboard from "./BusinessDashboard";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BusinessDashboard />} />
        <Route path="/hivers" element={<HiversDashboard />} />
        <Route path="/farmers" element={<FarmersDashboard />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
