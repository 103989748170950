import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import Logo from "./logo.png";

const timeRanges = {
  "6months": 180,
  "3months": 90,
  "1month": 30,
  "2weeks": 14,
  "1week": 7,
  today: "today",
};

// Mock data generation function
const generateMockData = (timeRange) => {
  const data = [];
  const now = new Date();

  if (timeRange === "today") {
    // Generate hourly data for today
    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, "0");
      data.push({
        time: `${hour}:00`,
        temperature: Math.random() * 10 + 20, // 20-30°C
        humidity: Math.random() * 20 + 60, // 60-80%
        motion: Math.floor(Math.random() * 100), // 0-100 (arbitrary units)
        pollinationEfficiency: Math.random() * 30 + 70, // 70-100%
      });
    }
  } else {
    // Generate daily data for other time ranges
    for (let i = timeRange - 1; i >= 0; i--) {
      const date = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - i
      );
      data.push({
        date: date.toISOString().split("T")[0],
        temperature: Math.random() * 10 + 20,
        humidity: Math.random() * 20 + 60,
        motion: Math.floor(Math.random() * 100),
        pollinationEfficiency: Math.random() * 30 + 70, // 70-100%
      });
    }
  }

  return data;
};
const StatCard = ({ title, value, icon }) => (
  <div className="bg-white rounded-lg shadow p-4 flex items-center justify-between">
    <div>
      <h3 className="text-sm font-medium text-gray-500">{title}</h3>
      <p className="text-2xl font-semibold text-gray-900">{value}</p>
    </div>
    <div className="text-blue-500">{icon}</div>
  </div>
);
const HiversDashboard = () => {
  const [selectedHive, setSelectedHive] = useState("1");
  const [timeRange, setTimeRange] = useState("6months");

  const data = generateMockData(timeRanges[timeRange]);

  const renderChart = (dataKey, color, chartType = "line") => (
    <ResponsiveContainer width="100%" height={400}>
      {chartType === "line" ? (
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={timeRange === "today" ? "time" : "date"}
            tickFormatter={
              timeRange === "today" ? (time) => time.split(":")[0] : undefined
            }
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey={dataKey} stroke={color} />
        </LineChart>
      ) : (
        <AreaChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={timeRange === "today" ? "time" : "date"}
            tickFormatter={
              timeRange === "today" ? (time) => time.split(":")[0] : undefined
            }
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey={dataKey}
            stroke={color}
            fill={color}
            fillOpacity={0.3}
          />
        </AreaChart>
      )}
    </ResponsiveContainer>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex space-x-4">
        <img
          src={Logo}
          className="h-12 w-fit items-center"
          alt="Business dashboard"
        />
        <h1 className="text-3xl font-bold mb-6">Welcome David</h1>
      </div>
      <div className="flex flex-wrap justify-end gap-4 mb-4">
        <div className="relative">
          <select
            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={selectedHive}
            onChange={(e) => setSelectedHive(e.target.value)}
          >
            <option value="1">Hive 1</option>
            <option value="2">Hive 2</option>
            <option value="3">Hive 3</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>

        <div className="relative">
          <select
            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value)}
          >
            <option value="6months">6 months</option>
            <option value="3months">3 months</option>
            <option value="1month">1 month</option>
            <option value="2weeks">2 weeks</option>
            <option value="1week">1 week</option>
            <option value="today">Today</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
        <StatCard title="Average Temperature" value={24.76} icon="👨‍🌾" />
        <StatCard title="Average Humidity" value={22.09} icon="🐝" />
        <StatCard title="Averate Mobility" value={50.4} icon="💰" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white shadow rounded-lg p-4">
          <h2 className="text-lg font-semibold mb-2">Temperature</h2>
          <div className="h-fit">{renderChart("temperature", "#8884d8")}</div>
        </div>

        <div className="bg-white shadow rounded-lg p-4">
          <h2 className="text-lg font-semibold mb-2">Humidity</h2>
          <div className="h-fit">{renderChart("humidity", "#82ca9d")}</div>
        </div>

        <div className="bg-white shadow rounded-lg p-4">
          <h2 className="text-lg font-semibold mb-2">Motion</h2>
          <div className="h-fit">{renderChart("motion", "#ffc658")}</div>
        </div>
      </div>
    </div>
  );
};

export default HiversDashboard;
