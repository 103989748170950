import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";
import Logo from "./logo.png";

const mockData = [
  { date: "2024-10-03", beeActivity: 0, beeFlightTime: 20, temp: 15 },
  { date: "2024-10-04", beeActivity: 0, beeFlightTime: 40, temp: 18 },
  { date: "2024-10-05", beeActivity: 0, beeFlightTime: 30, temp: 16 },
  { date: "2024-10-06", beeActivity: 0, beeFlightTime: 35, temp: 17 },
  { date: "2024-10-07", beeActivity: 10, beeFlightTime: 50, temp: 20 },
  { date: "2024-10-08", beeActivity: 20, beeFlightTime: 60, temp: 22 },
];
const StatCard = ({ title, value, icon }) => (
  <div className="bg-white rounded-lg shadow p-4 flex items-center justify-between">
    <div>
      <h3 className="text-sm font-medium text-gray-500">{title}</h3>
      <p className="text-2xl font-semibold text-gray-900">{value}</p>
    </div>
    <div className="text-blue-500">{icon}</div>
  </div>
);
const Card = ({ children, className }) => (
  <div className={`bg-white  rounded-lg shadow-md p-4 py-8 ${className}`}>
    {children}
  </div>
);

const GoogleMap = ({ locations }) => {
  const mapRef = React.useRef(null);

  React.useEffect(() => {
    if (mapRef.current && window.google && locations.length > 0) {
      const map = new window.google.maps.Map(mapRef.current, {
        zoom: 5,
        mapTypeId: "hybrid",
      });
      const bounds = new window.google.maps.LatLngBounds();

      locations.forEach((location) => {
        const position = new window.google.maps.LatLng(
          location.lat,
          location.lng
        );
        bounds.extend(position);

        new window.google.maps.Marker({
          position: position,
          map: map,
          title: location.name,
        });
      });

      map.fitBounds(bounds);

      // Adjust zoom if there's only one location
      if (locations.length === 1) {
        map.setZoom(15);
      }
    }
  }, [locations]);

  return <div ref={mapRef} className="w-full h-96" />;
};

const FarmersDashboard = () => {
  // Example coordinates for multiple locations
  const [startDate, setStartDate] = useState(mockData[0].date);
  const [endDate, setEndDate] = useState(mockData[mockData.length - 1].date);
  const [filteredData, setFilteredData] = useState(mockData);

  useEffect(() => {
    const filtered = mockData.filter((item) => {
      const itemDate = new Date(item.date);
      return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
    });
    setFilteredData(filtered);
  }, [startDate, endDate]);
  const data = [
    { name: "Monitored", value: 44 },
    { name: "Not Monitored", value: 8 },
  ];

  const COLORS = ["#48BB78", "#F56565"];
  const locations = [
    {
      id: 1,
      name: "Location 1",
      lat: -1.374854,
      lng: 38.010273,
      acres: 15.07,
      drops: 5,
    },
    {
      id: 2,
      name: "Location 2",
      lat: -1.373954,
      lng: 38.010273,
      acres: 10.5,
      drops: 3,
    },
    {
      id: 3,
      name: "Location 3",
      lat: -1.373954,
      lng: 38.011173,
      acres: 8.3,
      drops: 4,
    },
    {
      id: 4,
      name: "Location 4",
      lat: -1.373954,
      lng: 38.01127,
      acres: 8.3,
      drops: 4,
    },
    {
      id: 5,
      name: "Location 5",
      lat: -1.373759,
      lng: 38.011173,
      acres: 8.3,
      drops: 4,
    },
    // Add more locations as needed
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex space-x-4">
        <img
          src={Logo}
          className="h-12 w-fit items-center"
          alt="Business dashboard"
        />
        <h1 className="text-3xl font-bold mb-6">Welcome Moses</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-4 gap-4 mb-8">
        <StatCard title="Pollination Efficiency" value="78%" icon="🐝" />
        <StatCard title="Land Pollinated" value="3 Hectares" icon="🌱" />
      </div>
      <div className="grid grid-cols-3 gap-4 p-4 ">
        <Card className="col-span-1 flex flex-col items-center justify-center">
          <h2 className="text-lg font-semibold mb-4">Bee activity</h2>
          <div className=" text-center">
            <h1 className="text-6xl font-bold nb-2">5</h1>
            <h1 className="text-xl text-yellow-500 mb-2">
              Currently: Medium rate
            </h1>
            <h1 className="text-sm text-gray-500">
              Still early in the morning
            </h1>
          </div>
        </Card>

        <Card className="col-span-1 flex flex-col items-center justify-center">
          <h2 className="text-lg font-semibold mb-4">Frames</h2>
          <div className="text-center">
            <div className="text-sm text-gray-500">Per acre</div>
            <div className="text-4xl font-bold">6</div>
            <div className="text-sm">Commitment</div>
            <div className="text-4xl font-bold text-green-500 mb-2">16.22</div>
            <div className="text-sm text-gray-500 mb-2">Currently Avg.</div>
            <div className="text-sm">
              Your bees have definitely been busy. They already met the
              commitment.
            </div>
          </div>
        </Card>
        <Card className="col-span-1">
          <h2 className="text-lg font-semibold mb-4">Drop map</h2>
          <div className="text-sm mb-2">Total: 3 Acres, 3 Drops</div>
          <GoogleMap locations={locations} />
        </Card>

        <Card className="col-span-1">
          <h2 className="text-lg font-semibold mb-4">Hives</h2>
          <div className="text-sm">52 Hives in Total</div>
          <PieChart width={400} height={400}>
            <Pie
              data={data}
              cx={200}
              cy={200}
              labelLine={false}
              label={({ name, percent }) =>
                `${name}: ${(percent * 100).toFixed(0)}%`
              }
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </Card>
        <Card className="col-span-2">
          <h2 className="text-lg font-semibold mb-4">
            Bee flight time & Bee activity
          </h2>
          <div className="flex space-x-2 justify-end mb-4">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border rounded px-2 py-1"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border rounded px-2 py-1"
            />
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={filteredData}>
              <XAxis dataKey="date" />
              <YAxis />
              <Line type="monotone" dataKey="beeActivity" stroke="#ff7300" />
              <Line type="monotone" dataKey="beeFlightTime" stroke="#ffd700" />
              <Line type="monotone" dataKey="temp" stroke="#82ca9d" />
              <Tooltip />
              <Legend />
            </LineChart>
          </ResponsiveContainer>
        </Card>
      </div>
    </div>
  );
};

export default FarmersDashboard;
