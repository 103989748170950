import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import Logo from "./logo.png";
// Mock data - replace with your actual data fetching logic
const generateMockData = (duration) => {
  // Logic to generate mock data based on duration
  return Array.from({ length: duration }, (_, i) => ({
    name: `Day ${i + 1}`,
    farmers: Math.floor(Math.random() * 100) + 50,
    beekeepers: Math.floor(Math.random() * 50) + 20,
    revenue: Math.floor(Math.random() * 10000) + 5000,
    acres: Math.floor(Math.random() * 1000) + 500,
  }));
};

const StatCard = ({ title, value, icon }) => (
  <div className="bg-white rounded-lg shadow p-4 flex items-center justify-between">
    <div>
      <h3 className="text-sm font-medium text-gray-500">{title}</h3>
      <p className="text-2xl font-semibold text-gray-900">{value}</p>
    </div>
    <div className="text-blue-500">{icon}</div>
  </div>
);

const DurationSelect = ({ value, onChange }) => (
  <select
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
  >
    <option value={14}>2 Weeks</option>
    <option value={30}>1 Month</option>
    <option value={90}>3 Months</option>
    <option value={180}>6 Months</option>
    <option value={365}>1 Year</option>
    <option value={730}>2 Years</option>
  </select>
);

const BusinessDashboard = () => {
  const [duration, setDuration] = useState(30);
  const data = generateMockData(duration);

  const latestData = data[data.length - 1];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex space-x-4">
        <img
          src={Logo}
          className="h-12 w-fit items-center"
          alt="Business dashboard"
        />
        <h1 className="text-3xl font-bold mb-6">Business Dashboard</h1>
      </div>

      {/* <div className="mb-6 flex justify-end w-1/4">
        <DurationSelect value={duration} onChange={setDuration} />
      </div> */}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <StatCard title="Farmers" value="2,150" icon="👨‍🌾" />
        <StatCard title="Beekeepers" value="3,049" icon="🐝" />
        <StatCard title="Revenue" value="Kshs 4.4M" icon="💰" />
        <StatCard title="Acres Pollinated" value="2,000 acres" icon="🌱" />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Farmers and Beekeepers</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="farmers" fill="#8884d8" />
              <Bar dataKey="beekeepers" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Revenue</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Acres Pollinated</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="acres" fill="#ffc658" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default BusinessDashboard;
